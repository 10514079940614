<template>
<div class="index_style">
  <van-nav-bar
      style="background: #e9e9ef;"
      title="资料库"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
  />
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>
      <img style="height: 5.33rem" :src="require('../assets/img/classification/banner.png')" />
    </van-swipe-item>
  </van-swipe>
  <div class="main_style">
      <div class="sousuoDIV">
        <van-search
            v-model="tiaojian"
            shape="round"
            background="#fff"
            placeholder="请输入仪器型号"
            @search="onSearch"
            :left-icon="require('./img/sousuo.png')"
            :clearable="false"
        >
        </van-search>
        <button class="sousuo" @click="onSearch">搜索</button>
      </div>
    <!-- list展示区 -->
    <div class="show_list">
      <div style="font-size: 16px;text-align: center;color:#a5a1a1;margin-top: 2rem" v-show="show1">暂无数据</div>
      <van-row gutter="5">
        <van-col class="list_one" span="8" v-for="(row,i) in list" :key="i" @click="queryList(row)">
          <div class="div_img">
          <img style="height: 1.17rem;border-radius: .15rem;" :src="getPic(row.pic)">
          </div>
          <div class="div_text">{{ row.name }}</div>
        </van-col>
      </van-row>
    </div>
  </div>

<!--  遮罩 用于小程序webview跳转-->
  <div class="maskT" v-if="flag"></div>
  <div class="maskB" v-if="flag"></div>


</div>
</template>

<script>
import classificationApi from "@/utils/classificationApi";
import axios from "axios";
import TEMPURL from "@/utils/tempurl";

export default {
  name: "Classification",
  data() {
    return {
      tiaojian: '',
      fID:'',
      list: '',
      oneData: '',
      flag:true,
      show1: false,
    }
  },
  created() {
    // 获取页面初始数据
    let params={
      id: '',
      parentId:''
    }
    this.getListCP(params)
  },
  mounted() {
    // console.log(this.$route.query)
    let _flag = this.$route.query.flag
    if(_flag=='false'){
      // console.log(1111)
      this.flag = false
    }else {
      // console.log(2222)
      this.flag = true
    }
  },
  methods:{
    async getListCP(params) {
      const { data,code } = await classificationApi.getListCP(params)
      if (code === 200){
        this.list=data
        if(data==""){
          this.show1=true
        }else {
          this.show1=false
        }
      }

    },
    async getListCPID(params) {
      const { data,code } = await classificationApi.getListCP(params)
      if (code === 200){
        this.oneData=data
      }
    },
    getPic(pic){
      return TEMPURL+pic
    },
    queryList(row){
      if(row.node===1){
        let params={
          id: '',
          parentId: row.id
        }
        this.getListCP(params)
      }else {
        // let params={
        //   id: row.name,
        //   parentId:''
        // }
        // this.getListCPID(params)
        // console.log(row.id)
        // console.log(row.pic)
        // console.log(row.description)
        this.$router.push({
            name : 'classProduct',
            params : {
              id : row.id,
              pic: row.pic,
              description: row.description
            }
          })
        localStorage.setItem("id", row.id)
        localStorage.setItem("description", row.description)
      }

    },
    // 返回
    onClickLeft() {
      this.$router.push({
        name: 'home'
      })
    },
    //查询
    onSearch(val) {
      console.log(this.tiaojian)
      let params={
        id: this.tiaojian,
        parentId:''
      }
      this.getListCP(params)
    },
  },
}
</script>

<style lang="less" scoped>
.index_style{
  background: #d4d5dd;
  position: relative;
  .maskT{
    height: 0.96667rem;
    background-color: #e7e6ed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
  }
  .maskB{
    height: 2.96667rem;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 11;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    //line-height: 150px;
    text-align: center;
    //background-color: #39a9ed;
  }

  .main_style{
    background: #fff;
    border-radius: .3rem .3rem 0 0;
    padding: .2rem;
  }
  ::v-deep.van-icon{
    color: #323233;
    font-size: .6rem;
  }
  .sousuoDIV{
    text-align: right;
    height: .8rem;
  }
  .sousuo{
    margin-right: 10px;
    position: relative;
    top: -1.47rem;
    margin-right: .45rem;
    font-size: .4rem;
    text-align: center;
    line-height: 0.69rem;
    width: 1.76rem;
    height: 0.72rem;
    border: 0;
    color: #fff;
    border-radius: .5rem;
    background: #4161EC;
  }
.show_list{
  padding: .5rem;
  background: #fff;
  min-height: 12rem;
  .list_one{
    text-align: center;
    .div_img{
      border-radius: .5rem;
      overflow: hidden;
    }
    .div_text{
      font-size: .35rem;
      color: #727272;
    }
    margin-top: .53rem;
  }

}



}
</style>
