import request from "@/utils/request";
import TEMPURL from "@/utils/tempurl";

const classificationApi = {
    getListCP(params){
        return request.post(TEMPURL+'/pub/qd/getListCP',params)
    },
    getimgData(params){
        return request.post(TEMPURL+'/pub/qd/getimgData',params)
    },
}


export default classificationApi